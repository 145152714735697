import { useReviewProjectTab } from '#imports';
import { AssetsColsMappings } from '@/utils/constants';

export const getErrorClass = (stepId, isBorder = false) => {
  const hasErrorCode = useReviewProjectTab(stepId);
  let errorClass = '';
  if (hasErrorCode === 1 && isBorder) {
    errorClass = 'bg-yellow-600 text-white border-yellow-600 border-2';
  } else if (hasErrorCode === 1) {
    errorClass = 'bg-yellow-600 text-yellow-600 border-yellow-600 after:border-yellow-400 border-2';
  }
  return errorClass;
};

export const getLiErrorClass = (stepId, isLiClass = false) => {
  const hasErrorCode = useReviewProjectTab(stepId);
  let errorClass = '';
  if (hasErrorCode === 1 && isLiClass) {
    errorClass = 'after:border-yellow-400  ';
  } else if (hasErrorCode === 1) {
    errorClass = 'border-b-yellow-500 ';
  }
  return errorClass;
};

export const getListActiveClass = (className, stepId) => {
  if (className === 'active') {
    const errorClass = getLiErrorClass(stepId, true);
    return errorClass.length === 0 ? ` text-blue-400` : errorClass;
  } else if (className === 'InActive') {
    const errorClass = getLiErrorClass(stepId);
    return errorClass.length === 0 ? 'text-white' : errorClass;
  } else if (className === 'last') {
    return 'after:hidden';
  }
};

export const getClass = (className, stepId) => {
  if (className === 'active') {
    const errorClass = getErrorClass(stepId);
    return errorClass.length === 0 ? `bg-blue-600 text-blue-600 border-2 border-blue-600` : errorClass;
  } else if (className === 'InActive') {
    const errorClass = getErrorClass(stepId, true);
    return errorClass.length === 0
      ? 'bg-white after:border-gray-400 border-2 border-blue-400 text-blue-600'
      : errorClass;
  } else if (className === 'last') {
    return 'border-2 border-blue-400 bg-white after:hidden';
  }
};

export const activeClass = (stepId, activeStepId) => {
  if (useReviewProjectTab(stepId) === 2 || stepId === activeStepId) {
    return 'complete-fill';
  } else if (useReviewProjectTab(stepId) === 3) {
    return 'partially-fill';
  } else if (useReviewProjectTab(stepId) === 4) {
    return 'question';
  } else {
    return 'no-visits';
  }
};

export const getFleetClass = (stepId, item, isViewMode = false) => {
  let fleetClass = '';
  if (useReviewProjectTab(stepId, item.id) === 3) {
    fleetClass = 'border border-yellow-400';
  } else if (useReviewProjectTab(stepId, item.id) === 2 && isViewMode) {
    fleetClass = 'border border-blue-400';
  } else {
    fleetClass = 'border border-gray-400';
  }

  return fleetClass;
};

export const isExpandableCol = (col) => {
  return ['source', 'vintage'].includes(col);
};

export const getFilteredColumns = (columns) => {
  let output = '';

  columns?.forEach((col) => {
    output += AssetsColsMappings[col?.key] + ',';
  });
  output = output ? output.replace(/(^,)|(,$)/g, '') : undefined;

  return output;
};
