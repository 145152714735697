<template>
  <ElementsButtonsTheButton
    v-tooltip.right="{ content: $t('general.asset_filter'), html: true }"
    :background="`button button--white h-8`"
    element-id="action-sort"
    @click="handleAssetFilterAction"
  >
    <SvgSprite class="w-4 h-4 fill-white transition duration-75" symbol="asset-filter" />
    <AssetsInventoryAssetFilter
      v-if="openAssetFilter"
      v-model="openAssetFilter"
    />
  </ElementsButtonsTheButton>
</template>
<script setup>
import { ref } from 'vue';
import { SvgSprite } from 'vue-svg-sprite';


const openAssetFilter = ref(false);


const handleAssetFilterAction = () => {
  openAssetFilter.value = !openAssetFilter.value;
};
</script>
