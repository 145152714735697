import useProjectStore from '@/stores/useProjectStore';
import { storeToRefs } from 'pinia';
import { CREDIT_POSTED, PROJECT_VERIFIED } from '@/utils/constants.js';
import useProjectVerifer from '@/composables/useProjectVerifer.js';

export default (stepId, instanceId = null) => {
  const projectObj = useProjectStore();
  const { getSteppers, getProject } = storeToRefs(projectObj);
  let stepReview = 1;
  let stepArray = [stepId];
  if (stepId > 1) {
    let newStepReview = 0;
    const isVerifer = useProjectVerifer();
    let isStepVerified = true;
    getSteppers?.value?.instances
      ?.filter((instance) => (instanceId ? instance?.id === instanceId : true))
      ?.map((instance) => {
        let isInstanceVerified = true;
        instance?.reporting_units
          ?.filter((unit) => stepArray.includes(unit.stepId))
          ?.map((unit) => {
            isInstanceVerified = isInstanceVerified && unit.is_verified;
            const partiallyFilledLength = unit?.formAttr?.filter(
              (formData) =>
                formData?.value === null ||
                formData?.value === '' ||
                (formData?.formType?.input_source?.required_docs
                  ? !formData?.documents || formData?.documents?.length === 0
                  : false)
            ).length;
            if (partiallyFilledLength > 0 || partiallyFilledLength === unit?.formAttr?.length) {
              newStepReview = 3;
            } else if (
              partiallyFilledLength === 0 &&
              unit?.formAttr?.every(
                (formData) =>
                  (formData?.value || formData?.value === 0) &&
                  (formData?.formType?.input_source?.required_docs ? formData?.documents?.length > 0 : true)
              )
            ) {
              newStepReview = 2;
            } else {
              newStepReview = 1;
            }
            if (
              !isVerifer &&
              (unit?.messages?.some((msg) => msg?.is_available && msg.children.length === 0) ||
                unit?.formAttr?.some((formData) =>
                  formData?.messages?.some((msg) => msg?.is_available && msg.children.length === 0)
                ))
            ) {
              newStepReview = 4;
            }

            if (
              isVerifer &&
              (unit?.messages?.some((msg) => !msg?.is_resolved || msg.children.length === 0) ||
                unit?.formAttr?.some((formData) =>
                  formData?.messages?.some((msg) => !msg?.is_resolved || msg.children.length === 0)
                ) ||
                unit?.items?.some((formData) =>
                  formData?.messages?.some((msg) => !msg?.is_resolved || msg.children.length === 0)
                ))
            ) {
              newStepReview = 4;
            }
            if (newStepReview > stepReview) {
              stepReview = newStepReview;
            }
            return unit;
          });
        isStepVerified = isStepVerified && isVerifer && isInstanceVerified;
        if (isVerifer && isInstanceVerified && instanceId) {
          stepReview = 5;
        }
        return instance;
      });
    if (isStepVerified) {
      stepReview = 5;
    }
  } else {
    if (getProject?.value?.project_info?.length > 0) {
      stepReview = 2;
    }
    return stepReview;
  }
  if ([PROJECT_VERIFIED, CREDIT_POSTED].includes(getProject?.value?.status)) {
    stepReview = 2;
  }
  return stepReview;
};
