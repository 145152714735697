<template>
  <Teleport to="body">
    <HocModalBox v-if="modelValue" :can-close="true">
      <div
        class="fixed bg-white shadow-lg top-0 right-0 z-[1055] h-full w-2/5 overflow-x-auto justify-center items-center"
      >
        <div
          class="sticky border-b border-gray-200 top-0 p-3 pb-2 mb-3 bg-white z-10 flex items-left justify-leftflex flex-row items-start justify-between rounded-t"
        >
          <h3 class="text-base font-semibold flex items-center justify-left">
            <img
              alt="back"
              class="w-4 h-4 cursor-pointer mr-2"
              src="@/assets/svg/back.svg"
              @click="$emit('update:modelValue', false)"
            />
            {{ $t('assets.filter_by') }}
          </h3>
          <button
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="staticModal"
            type="button"
            @click="$emit('update:modelValue', false)"
          >
            <SvgSprite class="w-4 h-4" symbol="close" />
          </button>
        </div>

        <div
          class="relative px-6 pb-0 flex-auto border-0 flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div class="my-0 text-slate-500 text-lg leading-relaxed">
            <div>
              <h6 class="text-base 2xl:text-lg font-bold text-gray-600 mb-2">{{ $t('general.asset_type') }}</h6>
              <div class="items-center mb-5 grid grid-cols-2 gap-2">
                <div v-for="asset in assetTypes" :key="asset.id" class="flex items-center">
                  <input
                    :id="asset.id"
                    :checked="isChecked(asset.id)"
                    :class="checkBoxClasses"
                    :value="asset.id"
                    name="assetType"
                    type="checkbox"
                    @change="(e) => handleAssetType(e)"
                  />
                  <label :for="asset.id" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                    asset.name
                  }}</label>
                </div>
              </div>
            </div>

            <div>
              <h6 class="text-base 2xl:text-lg font-bold text-gray-600 mb-2">
                {{ $t('assets.basket.tr_available_qty') }}
              </h6>
              <div class="grid gap-6 mb-3 md:grid-cols-2">
                <div>
                  <ElementsInputsTheLabel
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    label="Minimum"
                  ></ElementsInputsTheLabel>
                  <ElementsInputsTheInput
                    :class="inputFieldClasses"
                    :tabindex="0"
                    :value="assetFilter?.available?.minimum"
                    element-id="available_minimum"
                    placeholder="Minimum Available Qty."
                    @change="(e) => handleChange(e)"
                  ></ElementsInputsTheInput>
                </div>

                <div>
                  <ElementsInputsTheLabel
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    label="Maximum"
                  ></ElementsInputsTheLabel>
                  <ElementsInputsTheInput
                    :class="inputFieldClasses"
                    :tabindex="0"
                    :value="assetFilter?.available?.maximum"
                    element-id="available_maximum"
                    placeholder="Maximum Available Qty."
                    @change="(e) => handleChange(e)"
                  ></ElementsInputsTheInput>
                </div>
              </div>
              <div class="flex items-start mb-6">
                <div class="flex items-center h-5">
                  <input
                    id="available_includeVintage"
                    :checked="!!assetFilter?.available?.includeVintage"
                    :class="checkBoxClasses"
                    :value="true"
                    name="available_includeVintage"
                    type="checkbox"
                    @change="(e) => handleChange(e, true)"
                  />
                </div>
                <label class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="available_includeVintage">
                  {{ $t('general.include_vintage') }}</label
                >
              </div>
            </div>

            <div>
              <h6 class="text-base 2xl:text-lg font-bold text-gray-600 mb-2">
                {{ $t('assets.basket.tr_posted_qty') }}
              </h6>
              <div class="grid gap-6 mb-3 md:grid-cols-2">
                <div>
                  <ElementsInputsTheLabel
                    :label="$t('assets.minimum')"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  ></ElementsInputsTheLabel>
                  <ElementsInputsTheInput
                    :class="inputFieldClasses"
                    :placeholder="`${$t('assets.minimum')} ${$t('assets.basket.tr_posted_qty')}`"
                    :tabindex="0"
                    :value="assetFilter?.posted?.minimum"
                    element-id="posted_minimum"
                    @change="(e) => handleChange(e)"
                  ></ElementsInputsTheInput>
                </div>

                <div>
                  <ElementsInputsTheLabel
                    :label="$t('assets.maximum')"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  ></ElementsInputsTheLabel>
                  <ElementsInputsTheInput
                    :class="inputFieldClasses"
                    :placeholder="`${$t('assets.maximum')} ${$t('assets.basket.tr_posted_qty')}`"
                    :tabindex="0"
                    :value="assetFilter?.posted?.maximum"
                    element-id="posted_maximum"
                    @change="(e) => handleChange(e)"
                  ></ElementsInputsTheInput>
                </div>
              </div>
              <div class="flex items-start mb-6">
                <div class="flex items-center h-5">
                  <input
                    id="posted_includeVintage"
                    :checked="!!assetFilter?.posted?.includeVintage"
                    :class="checkBoxClasses"
                    :value="true"
                    name="posted_includeVintage"
                    type="checkbox"
                    @change="(e) => handleChange(e, true)"
                  />
                </div>
                <label class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="posted_includeVintage">
                  {{ $t('general.include_vintage') }}</label
                >
              </div>
              <div>
                <div class="grid gap-6 mb-6 md:grid-cols-1">
                  <div>
                    <ElementsInputsTheLabel
                      :label="$t('general.vintage_name')"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    ></ElementsInputsTheLabel>
                    <ElementsInputsTheInput
                      :class="inputFieldClasses"
                      :placeholder="$t('general.vintage_name')"
                      :tabindex="0"
                      :value="assetFilter?.vintage"
                      element-id="vintage"
                      type="text"
                      @change="(e) => handleChange(e)"
                    ></ElementsInputsTheInput>
                  </div>
                </div>
                <!-- Created Date  -->
                <div>
                  <h6 class="text-base 2xl:text-lg font-bold text-gray-600 mb-2">{{ $t('general.created_date') }}</h6>
                  <div class="grid gap-6 mb-6 md:grid-cols-2">

                    <div>
                      <ElementsInputsTheLabel
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        label="From"
                      ></ElementsInputsTheLabel>
                      <ElementsInputsTheInput
                        :class="inputFieldClasses"
                        :tabindex="0"
                        :value="assetFilter?.creditedFrom"
                        element-id="creditedFrom"
                        type="date"
                        :max="currentDayDate"
                        @change="(e) => handleChange(e)"
                      ></ElementsInputsTheInput>
                    </div>

                    <div>
                      <ElementsInputsTheLabel
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        label="To"
                      ></ElementsInputsTheLabel>
                      <ElementsInputsTheInput
                        :class="inputFieldClasses"
                        :tabindex="0"
                        :value="assetFilter?.creditedTo"
                        element-id="creditedTo"
                        :max="currentDayDate"
                        type="date"
                        @change="(e) => handleChange(e)"
                      ></ElementsInputsTheInput>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-0">
              <h6 class="text-base 2xl:text-lg font-bold text-gray-600 mb-2">{{ $t('general.updated_date') }}</h6>
              <div class="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <ElementsInputsTheLabel
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    label="From"
                  ></ElementsInputsTheLabel>
                  <ElementsInputsTheInput
                    :class="inputFieldClasses"
                    :tabindex="0"
                    :value="assetFilter?.updatedFrom"
                    element-id="updatedFrom"
                    :max="currentDayDate"
                    type="date"
                    @change="(e) => handleChange(e)"
                  ></ElementsInputsTheInput>
                </div>

                <div>
                  <ElementsInputsTheLabel
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    label="To"
                  ></ElementsInputsTheLabel>
                  <ElementsInputsTheInput
                    :class="inputFieldClasses"
                    :tabindex="0"
                    :value="assetFilter?.updatedTo"
                    element-id="updatedTo"
                    :max="currentDayDate"
                    type="date"
                    @change="(e) => handleChange(e)"
                  ></ElementsInputsTheInput>
                </div>
              </div>
            </div>

            <!--eslint-enable-->
            <div class="sticky bg-white bottom-0 items-center border-t pb-3">
                <div v-if="dateValidationError" class="flex mt-5 pl-2 ml-2  bottom-[10px] text-red-400">  {{ $t('menu_items.project_management.messages.correct_date_range') }} </div>
              <ElementsButtonsTheButton
                :class="primaryBtnClasses"
                class="mr-2"
                element-id="apply-filter"
                type="button"
                @click="handleApplyFilter"
                >{{ $t('buttons.apply') }}
              </ElementsButtonsTheButton>
              <ElementsButtonsTheButton
                :class="dangerBtnClasses"
                class="mt-5"
                element-id="reset-filter"
                type="button"
                @click="handleResetFilter"
                >{{ $t('buttons.reset') }}
              </ElementsButtonsTheButton>
            </div>
          </div>
        </div>
      </div>
    </HocModalBox>
    <div
      v-if="modelValue"
      class="opacity-25 fixed inset-0 z-40 bg-black"
      @click="$emit('update:modelValue', false)"
    ></div>
  </Teleport>
</template>
<script setup>
import { reactive, ref } from 'vue';
import useAssetStore from '@/stores/useAssetStore';
import { useFilterDateValidation } from '@/composables/useFilterDateValidation';
import {
  assetFilterObj,
  checkBoxClasses,
  dangerBtnClasses,
  inputFieldClasses,
  primaryBtnClasses
} from '@/utils/config';
import { storeToRefs } from 'pinia';

const assetObj = useAssetStore();
const { assetFilter, queryParams } = storeToRefs(assetObj);
const dateValidationError = ref(false);
const currentDayDate = ref(new Date().toISOString().split('T')[0])
const formData = ref({
  assetType: []
});
defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  columns: {
    type: Array,
    default: () => []
  }
});

const queryString = reactive({});

const assetTypes = ref([
  {
    id: 1,
    name: 'Carbon'
  },
  {
    id: 2,
    name: 'Water'
  },
  {
    id: 3,
    name: 'Plastic'
  },
  {
    id: 4,
    name: 'NextGen Gas'
  },
  {
    id: 5,
    name: 'TrustWell Rating Certificate'
  },
  {
    id: 'basket',
    name: 'Basket'
  }
]);

const emit = defineEmits(['update:modelValue', 'handleResetColumnFilter', 'handleShouldDisplayVinter']);

const handleApplyFilter = (isCloseFilter = true) => {
   dateValidationError.value = useFilterDateValidation(formData, 'creditedTo', 'creditedFrom');
    if(!dateValidationError.value){
       dateValidationError.value =  useFilterDateValidation(formData, 'updatedTo', 'updatedFrom');
        if(dateValidationError.value){
         return;
        }else {
         queryString.assetsFilters = assetFilter.value;
         queryString.page = 1;
         queryString.exportColumn = queryParams.value?.exportColumn;
         assetObj.handleList(queryString);
         if (isCloseFilter) {
          emit('update:modelValue', false);
         }
  }

 }else {
  return 
 }
};
const isChecked = (assetId) => {
  if (assetFilter.value && assetFilter.value.assetType) {
    return assetFilter.value.assetType.includes(assetId.toString());
  }
  return false;
};
const handleResetFilter = () => {
  dateValidationError.value = false
  assetObj.setAssetFilter(assetFilterObj());
  emit('update:modelValue', true);
  handleApplyFilter(false);
};

const handleAssetType = (e) => {
  formData.value = assetFilter.value;
  const index = formData?.value?.assetType?.indexOf(e.target.value);
  if (e.target.checked && index === -1) {
    if (typeof formData.value.assetType === 'undefined') {
      formData.value.assetType = [];
    }
    formData.value.assetType.push(e.target.value);
  } else {
    formData.value.assetType = formData?.value?.assetType?.filter((id) => id !== e.target.value);
  }
  assetObj.setAssetFilter(formData.value);
};

/* Handle form data */
const handleChange = (e, isBool = false) => {
  dateValidationError.value=false
  formData.value = assetFilter.value;
  const nameSplit = e.target.name?.split('_');
  if (nameSplit && nameSplit.length > 1) {
    const obj = nameSplit[0];
    const colName = nameSplit[1];
    formData.value = {
      ...formData.value,
      [obj]: {
        ...formData.value[obj],
        [colName]: isBool ? e.target.checked : e.target.value
      }
    };
  } else {
    formData.value = {
      ...formData.value,
      [e.target.name]: e.target.value
    };
  }
  assetObj.setAssetFilter(formData.value);
};
</script>
