<template>
  <div ref="tableRef" class="block 2xl:max-h-[800px] lg:max-h-[520px] md:max-h-[400px] pr-2" @scroll="scrolling">
    <slot name="table-header" />

    <table
      :id="tableId"
      :class="`${getTableClass()}  ${tableClass}`"
      aria-describedby="data grid to show the dynamic data in tabular form"
      class="table-auto text-sm text-left whitespace-nowrap text-gray-400 dark:text-gray-400 w-full"
    >
      <thead class="text-xs text-gray-700 parent">
      <ElementsDataGridTableHead
        v-if="columns"
        :columns="columns"
        :custom-class="`parent`"
        :extra-tds="0"
        :is-asset-filter="isAssetFilter"
        :is-checkbox-enabled="isCheckboxEnabled"
        :is-fixed="isFixed"
        :is-last-column="isLastColumn"
        :is-show-column-filter="isShowColumnFilter"
        :scroll-class="getScrollClass"
        @handle-check-box="handleCheckBox"
        @handle-sorting="(direction, colKey) => $emit('handleSorting', direction, colKey)"
        @handle-column-filter-action="$emit('handleColumnFilterAction')"
        @toggle-column-filter="(colKey) => $emit('toggleColumnFilter', colKey)"
      />
      <th v-else>&nbsp;</th>
      </thead>
      <tbody>
      <ElementsDataGridTableBody
        :columns="columns"
        :inner-columns="innerColumns"
        :is-asset-filter="isAssetFilter"
        :is-checkbox-enabled="isCheckboxEnabled"
        :is-fixed="isFixed"
        :is-last-column="isLastColumn"
        :is-show-column-filter="isShowColumnFilter"
        :rows="rows"
        :scroll-class="getScrollClass"
        @handle-check-box="handleCheckBox"
        @handle-active-row="setFirstRowActive"
      />
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { colGridClasses } from '@/utils/constants';

const props = defineProps({
  columns: {
    type: Array,
    required: true
  },
  innerColumns: {
    type: Array,
    default: () => [],
    required: false
  },
  rows: {
    type: Array,
    required: true
  },
  isAllCheckboxEnabled: {
    type: Boolean,
    default: false
  },
  isCheckboxEnabled: {
    type: Boolean,
    default: false
  },
  isShowColumnFilter: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  isCheckedAll: {
    type: Boolean,
    default: false
  },
  isAssetFilter: {
    type: Boolean,
    default: false
  },
  isFixed: {
    type: Boolean,
    default: false
  },
  isLastColumn: {
    type: Boolean,
    default: false
  },
  tableId: {
    type: String,
    default: 'grid-table'
  },
  tableClass: {
    type: String,
    default: ''
  },
  isResizable: { type: Boolean, default: false }
});
const lastScroll = ref(0);
const scrollClass = ref('');
const getScrollClass = computed(() => scrollClass.value);
const emit = defineEmits([
  'handleCheckBox',
  'handleColumnFilterAction',
  'handleSorting',
  'toggleColumnFilter',
  'onloaded'
]);
const handleCheckBox = (e, row) => {
  emit('handleCheckBox', e, row);
};
const isFirstRowActive = ref(false);
// const isResizableCol = props.columns?.some((col) => !!col.isResizable);

const setFirstRowActive = (rowFlag) => {
  isFirstRowActive.value = rowFlag;
};

const scrolling = (evt) => {
  if (props.isLastColumn) {
    const currentScroll = evt.target.scrollLeft;
    if (currentScroll <= 0) {
      scrollClass.value = '';
    }

    if (currentScroll > lastScroll.value) {
      scrollClass.value = 'right-shadow';
    }
    lastScroll.value = currentScroll;
  }
};
const getTableClass = () =>
  props.isResizable && colGridClasses[props.columns?.filter((col) => !col.shouldHide)?.length + 2];
onMounted(() => {
  emit('onloaded');
});
</script>
