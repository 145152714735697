<template>
  <template v-for="(row, indx) in rows" :key="row.id.title">
    <ElementsDataGridTableRow
      :class="row.isActive ? 'row-activated' : ''"
      :columns="columns"
      :custom-class="`parent`"
      :extra-tds="0"
      :is-asset-filter="false"
      :is-checkbox-enabled="isCheckboxEnabled"
      :is-child-row="!!row?.children"
      :is-fixed="isFixed"
      :is-last-column="isLastColumn"
      :is-show-column-filter="isShowColumnFilter"
      :parent-columns="columns"
      :row="row"
      :scroll-class="scrollClass"
      @expand-row="() => expandRow(row, indx)"
      @handle-check-box="handleCheckBox"
    />

    <template v-if="row.isActive">
      <ElementsDataGridTableHead
        v-if="updatedChildCols?.length > 0 && row?.children"
        :columns="updatedChildCols"
        :custom-class="` bg-grey-200 `"
        :can-resizable="false"
        :extra-tds="childrenExtraIds"
        :has-children="true"
        :is-fixed="isFixed"
        :is-last-column="isLastColumn"
        :parent-columns="columns"
        :parent-row="row"
        :rows="rows"
        :scroll-class="scrollClass"
        @handle-check-box="handleCheckBox"
        @handle-sorting="handleSorting"
        @handle-column-filter-action="$emit('handleColumnFilterAction')"
      />

      <template v-for="(child, i) in row?.children" :key="child.id">
        <ElementsDataGridTableRow
          v-if="updatedChildCols?.length > 0 && row?.children"
          :columns="updatedChildCols"
          :custom-class="`parent bg-gray-50 whitespace-nowrap w-full`"
          :extra-tds="childrenExtraIds"
          :has-children="true"
          :is-asset-filter="false"
          :is-checkbox-enabled="true"
          :is-fixed="isFixed"
          :is-last-column="isLastColumn"
          :is-last-row="i === row?.children.length - 1"
          :parent-columns="columns"
          :row="child"
          :scroll-class="scrollClass"
          @handle-check-box="handleCheckBox"
        />
      </template>
    </template>
  </template>
</template>
<script setup>
import useAssetStore from '@/stores/useAssetStore';
import { computed } from 'vue';

const assetObj = useAssetStore();
const props = defineProps({
  columns: {
    type: Array,
    required: true
  },
  innerColumns: {
    type: Array,
    default: () => [],
    required: false
  },
  rows: {
    type: Array,
    required: true
  },
  isAllCheckboxEnabled: {
    type: Boolean,
    default: false
  },
  isCheckboxEnabled: {
    type: Boolean,
    default: false
  },
  isShowColumnFilter: {
    type: Boolean,
    default: false
  },
  isAssetFilter: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  isCheckedAll: {
    type: Boolean,
    default: false
  },
  isFixed: {
    type: Boolean,
    default: false
  },
  isLastColumn: {
    type: Boolean,
    default: false
  },
  scrollClass: {
    type: String,
    default: ''
  }
});
// const activeTDs = computed(() => props.columns?.filter((col) => !col.shouldHide)?.length + 2);
const extraTds = computed(
  () => {
    const parentCols = props.columns?.filter((col) => !col.shouldHide)?.length;
    const childCols = props.innerColumns?.filter((col) => !col.shouldHide)?.length;

    return parentCols > childCols ? parentCols - childCols : 0;
  }
  // (props.innerColumns?.length > 0 &&
  //   props.columns?.filter((col) => !col.shouldHide)?.length -
  //     props.innerColumns?.filter((col) => !col.shouldHide)?.length) < 0 || 0
);
const childrenExtraIds = computed(() => extraTds.value);
const updatedChildCols = computed(() => props.innerColumns?.filter((col) => !col.shouldHide));
const emit = defineEmits(['handleCheckBox', 'handleColumnFilterAction', 'handleSorting', 'handleActiveRow']);
const handleCheckBox = (e, row) => {
  emit('handleCheckBox', e, row);
};
const expandRow = (row, indx) => {
  assetObj.getAssetInventory(row);
  if (indx === 0) {
    emit('handleActiveRow', true);
  } else {
    emit('handleActiveRow', false);
  }
};
const handleSorting = (evt) => {
  emit('handleSorting', evt);
};
</script>
