<template>
  <tr
    :class="`${getNormalizeValue(customClass)} ${!hasChildren ? ' sticky top-0 z-20' : ''} ${scrollClass}`"
    class="rounded-t overflow-hidden"
  >
    <th
      v-if="isAssetFilter || hasChildren"
      :class="`${
        isFixed ? 'px-4 py-2 sticky left-0 z-10 h-12 bg-gray-50 asset-checkbox' : 'px-4 py-2 h-12 bg-gray-50'
      }${isFirstFullWidth ? ' w-full' : ''} ${parentRow?.isActive ? ' w-full block ' : ``} `"
      class="border-t"
      scope="col"
    >
      <AssetsInventoryFilterButton :class="`${isAssetFilter ? 'visible' : 'invisible'}`" />
    </th>
    <th
      v-if="isAllCheckboxEnabled"
      :class="`${isFixed ? 'px-4 py-2 sticky left-0 z-10 bg-gray-50' : 'px-4 py-2 h-12 bg-gray-50'} ${getNormalizeValue(
        customClass
      )}`"
      class="border-t"
      scope="col"
    >
      <ElementsInputsTheCheckbox
        :id="`allCheckbox`"
        :is-checked-all="isCheckedAll"
        :selected-ids="selectedIds"
        value="all"
        @handle-check-box="handleCheckBox"
      />
    </th>
    <template v-for="col in columns" :key="col.key">
      <th
        v-if="!col?.shouldHide"
        :class="
          col.key === 'source' && isFixed
            ? `${col.key}  sticky left-0 bg-gray-50 z-10 ${
                columns.filter((col) => col.key === 'type' && !col.shouldHide)?.length ? 'left-28' : 'left-14'
              }  `
            : `${col.key}  ${getNormalizeValue(col?.className)} z-10 h-12 bg-gray-50 
           
           `
        "
        class="px-4 py-2 border-t"
        scope="col"
      >
        <span v-if="canResizable" class="resize-handle cursor-col-resize"></span>
        <div
          :class="`${
            parentColumns.length && col?.isRightAligned ? 'flex items-center justify-end' : 'flex items-center'
          }`"
        >
          <TheHtmlString :html-tag="'div'" :html-text="col.heading" class="text-sm 2xl:text-base text-gray-700" />

          <ElementsDataGridColumnSorting
            v-if="isSorting && col.sortable"
            :sort-key="col.key"
            :col-key="col.key"
            :selected-option="col?.direction"
            :can-remove-col="!col?.fixedCol"
            :is-open="col.key === activeCol"
            @handle-three-dot-menu="handleThreeDotMenu(col.key)"
            @handle-sorting="(direction) => handleSortingAction(direction, col.key)"
            @toggle-column-filter="$emit('toggleColumnFilter', col.key)"
          />
        </div>
        <div v-if="isLastColumn && col.key === 'source'" class="asset-source">&nbsp;</div>
      </th>
    </template>
    <template v-if="extraTds > 0">
      <th v-for="td in extraTdWithHiddenCols" :key="td" class="border-t p-2 h-12 bg-gray-50"><span>&nbsp;</span></th>
    </template>
    <th
      v-if="isLastColumn"
      :class="`${lastColumnClass} ${
        parentRow?.isActive ? ' active-child ' : `${scrollClass}--no-fill-gray `
      } ${getNormalizeValue(customClass)}`"
      class="text-right px-5 z-10 border-t"
      scope="col"
    >
      <SvgSprite
        v-tooltip.right="{ content: $t('general.column_filter'), html: true }"
        :class="{ invisible: !isShowColumnFilter }"
        class="w-3.5 h-3.5 m-auto cursor-pointer fill-gray-500 focus:outline-none"
        symbol="column-filter-new"
        @click="$emit('handleColumnFilterAction')"
      />
    </th>
  </tr>
</template>
<script setup>
import { SvgSprite } from 'vue-svg-sprite';
import { getNormalizeValue } from '@/utils/config';
import { computed, ref } from 'vue';
import { onMounted, onUnmounted } from '#imports';
const props = defineProps({
  columns: {
    type: Array,
    default: () => []
  },
  parentColumns: {
    type: Array,
    default: () => []
  },
  hasChildren: {
    type: Boolean,
    default: false
  },
  rows: {
    type: Array,
    default: () => []
  },
  parentRow: {
    type: Object,
    default: () => undefined
  },
  isAllCheckboxEnabled: {
    type: Boolean,
    default: false
  },
  extraTds: {
    type: Number,
    default: 0
  },
  isCheckboxEnabled: {
    type: Boolean,
    default: false
  },
  isShowColumnFilter: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  isCheckedAll: {
    type: Boolean,
    default: false
  },
  customClass: {
    type: String,
    default: ''
  },
  isFixed: {
    type: Boolean,
    default: false
  },
  isAssetFilter: {
    type: Boolean,
    default: false
  },
  isSorting: { type: Boolean, default: true },
  isLastColumn: {
    type: Boolean,
    default: false
  },
  scrollClass: {
    type: String,
    default: ''
  },
  canResizable: {
    type: Boolean,
    default: true
  }
});

const selectedIds = ref([]);
const activeCol = ref('');
const emit = defineEmits([
  'handleCheckBox',
  'handleSorting',
  'handleColumnFilterAction',
  'update:modelValue',
  'update:isCheckedAll',
  'toggleColumnFilter'
]);
const extraTdWithHiddenCols = computed(() => props.extraTds); //props.columns.filter(col => col.shouldHide)?.length  );
const isFirstFullWidth = computed(
  () => props.parentColumns?.length === 0 && props.columns.filter((col) => !col.shouldHide).length === 0
);
const handleCheckBox = (value) => {
  let ids = [];
  if (value === 'all') {
    ids = props.rows?.map((row) => row.id.title);
    emit('update:isCheckedAll', true);
  } else {
    emit('update:isCheckedAll', false);
    ids.push(value);
  }
  emit('handleCheckBox', ids);
  selectedIds.value = ids;
};

const lastColumnClass = computed(() => {
  let columnCls = `last-column bg-white `;
  if (props.isFixed) {
    columnCls = `${columnCls} sticky right-0`;
  } else {
    columnCls = `${columnCls} p-2 h-12`;
  }
  if (props.parentColumns.length) {
    columnCls = columnCls.replace(
      'bg-white',
      props.hasChildren ? `${props.scrollClass}--fill-color ` : `${props.scrollClass}--no-fill `
    );
  } else {
    columnCls = columnCls.replace('bg-white', 'bg-gray-50');
  }
  return columnCls;
});

const handleThreeDotMenu = (colKey) => {
  activeCol.value = activeCol.value === colKey ? '' : colKey;
};

// handle sorting click
const handleSortingAction = (direction, colKey) => {
  emit('handleSorting', direction, colKey);
  activeCol.value = '';
};

const handleClickOutside = (event) => {
  const colSortingWrapper = document.getElementById('colSortingWrapper-' + activeCol.value);
  if (colSortingWrapper && !colSortingWrapper.contains(event.target)) {
    activeCol.value = '';
  }
};
onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>
